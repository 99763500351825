import React, { useState } from "react";
import {
	View,
	Text,
	TextInput,
	StyleSheet,
	TouchableOpacity,
	Image,
	Linking,
} from "react-native";
import { api_qrscanned } from "../Api";
import CarouselProducts from "./Carousel";
import App from "../App";

const ProductPage = ({ navigation }) => {
	const [selectedRetailer, setselectedRetailer] = useState("");
	const [signupData, setsignupData] = useState({
		firstName: "",
		lastName: "",
		email: "",
		cell: "",
		preferredMethod: "",
	});

	const handleInstagramClick = () => {
		// window.location.href = "https://www.instagram.com/elizabetharden_sa/";
		const analyticsData = JSON.parse(localStorage.getItem("analyticsData"));
		window.open("https://www.instagram.com/elizabetharden_sa", "_blank");

		fetch(api_qrscanned, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				allocation:
					"EA - WHITE TEA ACTIVATION - Bubble tea Instagram follow",
				...analyticsData,
			}),
		})
			.then((response) => response.json())
			.then((data) => {
				// window.open(
				// 	"https://www.instagram.com/elizabetharden_sa",
				// 	"_blank",
				// );
			})
			.catch((error) => {
				console.error(error);
				this.setState({ isLoading: false });
			});
	};

	const handleFacebookClick = () => {
		const analyticsData = JSON.parse(localStorage.getItem("analyticsData"));
		// Do something with the form data
		window.open(
			"https://web.facebook.com/elizabethardensouthafrica/?_rdc=1&_rdr",
		);

		fetch(api_qrscanned, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				allocation:
					"EA - WHITE TEA ACTIVATION - Bubble tea Facebook like",
				...analyticsData,
			}),
		})
			.then((response) => response.json())
			.then((data) => {})
			.catch((error) => {
				console.error(error);
				this.setState({ isLoading: false });
			});
	};

	const originalWidth = 1294;
	const originalHeight = 1320;

	// Calculate the height based on the aspect ratio
	const height = (window.innerWidth * originalHeight) / originalWidth;
	if (selectedRetailer === "") {
		return (
			<View
				style={{
					flex: 1,
					padding: 10,
					// width: "100%",
					height: window.innerHeight,
					alignItems: "center",
					// justifyContent: "center",
					// marginBottom: 50,
					backgroundColor: "#FFF0E8",
				}}>
				<View>
					<Image
						source={require("../assets/EA LOGO horizontal.png")}
						style={{
							width: 200,
							height: 50,
							resizeMode: "cover",
							marginTop: 40,
						}}
					/>
				</View>
				<Text
					style={{
						color: "#000",
						fontWeight: "700",
						fontSize: 19,
						fontFamily: "Gotham",
						marginTop: 50,
					}}>
					Select a retailer
				</Text>
				<View
					style={{
						width: "100%",
						// flexDirection: "row",
						// flexWrap: "wrap",
						padding: 20,
						maxWidth: 400,
					}}>
					{/* {
						title: "Dis-Chem",
						url: "https://www.dischem.co.za/catalogsearch/result/?q=Elizabeth+arden&form_key=ObxPdedpN9DoxWyw",
					}, */}
					{[
						{
							title: "Clicks",
							url: "https://clicks.co.za/elizabeth-arden_white-tea-eau-de-toilette-spray-eau-florale-100ml/p/383064",
						},
						{
							title: "Edgars",
							url: "https://www.edgars.co.za/white-tea-florale-eau-de-parfum-edp-c3721330?gad_source=1&gclid=Cj0KCQjw3tCyBhDBARIsAEY0XNkREajBh7l6M7g1lV8lEHpoCcVOq86zHPxejy10xxWt0xya-LzIuE0aAosyEALw_wcB",
						},
						{
							title: "Woolworths",
							url: "https://www.woolworths.co.za/cat?Ntt=Elizabeth%20arden&Dy=1",
						},
						{
							title: "TakeAlot",
							url: "https://www.takealot.com/elizabeth-arden-white-tea-eau-florale-edt-100ml/PLID95395345?gad_source=1&gclid=Cj0KCQjw3tCyBhDBARIsAEY0XNkhO82WHgeZ-xgRAw5A9ugVYAWeunwXSAUlVK6aGYVBBk2RbDCECRUaAqbgEALw_wcB",
						},
						{
							title: "Truworths",
							url: "https://www.truworths.co.za/white-tea-eau-florale-edt/product/prod3176264",
						},
						{
							title: "Foschini",
							url: "https://bash.com/elizabeth-arden-white-tea-eau-florale-eau-de-toilette-000177aagg1/p?bash_campaign=19683289877&gad_source=1&gad_source=1&gclid=CjwKCAjw88yxBhBWEiwA7cm6pScKPtjU8jW4V-sPWqNKpubb2SoKj2258sErobgvOy9sDYl7ZIBfYBoC5YUQAvD_BwE&gclid=Cj0KCQjw3tCyBhDBARIsAEY0XNlnS_T5CLKhxem5B4FMfN1sQ8GJzOqO4i28CBamoEmK3Nf6mTsEtqcaAmOAEALw_wcB&idsku=1354837&skuId=1354838",
						},

						{
							title: "Superbalist",
							url: "https://superbalist.com/women/beauty/fragrances/elizabeth-arden-white-tea-eau-florale-edt-100ml/1313850?sku=5043731&gad_source=1&gclid=Cj0KCQjw3tCyBhDBARIsAEY0XNkxsvZAvoBykrOd13toQptZvk8y5yq8EN3ogoY7aoGZNSyIcUQEtbMaAjnYEALw_wcB&gclsrc=aw.ds",
						},
					].map((d, i) => (
						<TouchableOpacity
							style={{
								margin: 1,
								width: "100%",
							}}
							onPress={() => {
								setselectedRetailer(d.title);
							}}>
							<View
								style={{
									padding: 17,
									borderWidth: 1,
									justifyContent: "center",
									alignItems: "center",
									backgroundColor: "#FFF",
								}}>
								<Text
									style={{
										fontFamily: "Gotham",
										fontSize: 17,
										fontWeight: "500",
									}}>
									{d.title}
								</Text>
							</View>
						</TouchableOpacity>
					))}
				</View>
			</View>
		);
	} else {
		return (
			<View
				style={{
					flex: 1,
					alignItems: "center",
					justifyContent: "center",
					backgroundColor: "#FFF0E8",
					// paddingHorizontal: 16,
					maxWidth: 500,
					marginHorizontal: "auto",
				}}>
				{/* <View
			style={{
				display:
					signupData.preferredMethod !== "" ? "none" : "flex",
				alignItems: "center",
				width: "100%",
				marginBottom: 25,
			}}>
			<Image
				source={require("../assets/banner.jpg")}
				style={{
					width: window.innerWidth,
					height,
					resizeMode: "cover",
				}}
			/>
		</View> */}
				{/* <View
			style={{
				height: 20,
			}}
		/> */}
				<CarouselProducts />

				<View
					style={{
						flex: 1,
						alignItems: "center",
						justifyContent: "center",
						marginBottom: 50,
					}}>
					<View>
						<Image
							source={require("../assets/EA LOGO horizontal.png")}
							style={{
								width: 200,
								height: 50,
								resizeMode: "cover",
								marginTop: 40,
							}}
						/>
					</View>
					<View
						style={{
							flexDirection: "row",
							backgroundColor: "#FFF",
							borderRadius: 15,
							marginTop: 40,
							justifyContent: "center",
							alignItems: "center",
							padding: 20,
							width: "100%",
						}}>
						<View
							style={{
								justifyContent: "center",
								alignItems: "center",
							}}>
							<Image
								source={require("../assets/wteau1.png")}
								style={{
									resizeMode: "contain",
									height: 110,
									width: 110,
								}}
							/>
						</View>
						<View>
							<Text
								style={{
									color: "#000",
									fontWeight: "700",
									fontSize: 16,
									fontFamily: "Gotham",
									textAlign: "center",
								}}>
								White Tea Eau Florale EDT
							</Text>
							<View
								style={{
									flexDirection: "row",
									marginTop: 10,
								}}>
								<View
									style={{
										padding: 10,
										borderRadius: 10,
										borderWidth: 1,
										borderColor: "#797979",
										marginRight: 4,
									}}>
									<Text
										style={{
											color: "#000",
											fontWeight: "700",
											fontSize: 16,
											fontFamily: "Gotham",
											textAlign: "center",
										}}>
										100 mL
									</Text>
								</View>
								<View
									style={{
										padding: 10,
										borderRadius: 10,
										borderWidth: 1,
										borderColor: "#797979",
										marginRight: 4,
									}}>
									<Text
										style={{
											color: "#000",
											fontWeight: "700",
											fontSize: 16,
											fontFamily: "Gotham",
											textAlign: "center",
										}}>
										50 mL
									</Text>
								</View>
								<View
									style={{
										padding: 10,
										borderRadius: 10,
										borderWidth: 1,
										borderColor: "#797979",
									}}>
									<Text
										style={{
											color: "#000",
											fontWeight: "700",
											fontSize: 16,
											fontFamily: "Gotham",
											textAlign: "center",
										}}>
										30 mL
									</Text>
								</View>
							</View>
						</View>
					</View>

					<View
						style={{
							backgroundColor: "#FFF",
							borderRadius: 15,
							marginTop: 20,
							justifyContent: "center",
							alignItems: "center",
							padding: 20,
							width: "100%",
						}}>
						{/* <Text
							style={{
								color: "#000",
								fontWeight: "700",
								fontSize: 19,
								fontFamily: "Gotham",
								// marginTop: 50,
							}}>
							Buy Now
						</Text> */}
						<Text
							style={{
								color: "#000",
								fontWeight: "700",
								fontSize: 19,
								fontFamily: "Gotham",
								// marginTop: 50,
							}}>
							{selectedRetailer}
						</Text>

						<View
							style={{
								width: "100%",
								padding: 10,
								alignItems: "center",
								justifyContent: "center",
							}}>
							<Text
								style={{
									fontFamily: "Gotham",
									fontSize: 17,
									fontWeight: "500",
								}}>
								Avaliable in-store & online
							</Text>
						</View>
						<View
							style={{
								padding: 10,
								width: "100%",
								// display: "none",
							}}>
							<View
								style={{
									width: "100%",
									// flexDirection: "row",
									// flexWrap: "wrap",
									padding: 20,
								}}>
								{/* {
							title: "Dis-Chem",
							url: "https://www.dischem.co.za/catalogsearch/result/?q=Elizabeth+arden&form_key=ObxPdedpN9DoxWyw",
						}, */}
								{[
									{
										title: "Clicks",
										url: "https://clicks.co.za/elizabeth-arden_white-tea-eau-de-toilette-spray-eau-florale-100ml/p/383064",
									},
									{
										title: "Edgars",
										url: "https://www.edgars.co.za/white-tea-florale-eau-de-parfum-edp-c3721330?gad_source=1&gclid=Cj0KCQjw3tCyBhDBARIsAEY0XNkREajBh7l6M7g1lV8lEHpoCcVOq86zHPxejy10xxWt0xya-LzIuE0aAosyEALw_wcB",
									},
									{
										title: "Woolworths",
										url: "https://www.woolworths.co.za/cat?Ntt=Elizabeth%20arden&Dy=1",
									},
									{
										title: "TakeAlot",
										url: "https://www.takealot.com/elizabeth-arden-white-tea-eau-florale-edt-100ml/PLID95395345?gad_source=1&gclid=Cj0KCQjw3tCyBhDBARIsAEY0XNkhO82WHgeZ-xgRAw5A9ugVYAWeunwXSAUlVK6aGYVBBk2RbDCECRUaAqbgEALw_wcB",
									},
									{
										title: "Truworths",
										url: "https://www.truworths.co.za/white-tea-eau-florale-edt/product/prod3176264",
									},
									{
										title: "Foschini",
										url: "https://bash.com/elizabeth-arden-white-tea-eau-florale-eau-de-toilette-000177aagg1/p?bash_campaign=19683289877&gad_source=1&gad_source=1&gclid=CjwKCAjw88yxBhBWEiwA7cm6pScKPtjU8jW4V-sPWqNKpubb2SoKj2258sErobgvOy9sDYl7ZIBfYBoC5YUQAvD_BwE&gclid=Cj0KCQjw3tCyBhDBARIsAEY0XNlnS_T5CLKhxem5B4FMfN1sQ8GJzOqO4i28CBamoEmK3Nf6mTsEtqcaAmOAEALw_wcB&idsku=1354837&skuId=1354838",
									},

									{
										title: "Superbalist",
										url: "https://superbalist.com/women/beauty/fragrances/elizabeth-arden-white-tea-eau-florale-edt-100ml/1313850?sku=5043731&gad_source=1&gclid=Cj0KCQjw3tCyBhDBARIsAEY0XNkxsvZAvoBykrOd13toQptZvk8y5yq8EN3ogoY7aoGZNSyIcUQEtbMaAjnYEALw_wcB&gclsrc=aw.ds",
									},
								]
									.filter((m) => m.title === selectedRetailer)
									.map((d, i) => (
										<TouchableOpacity
											style={{
												margin: 1,
												width: "100%",
											}}
											onPress={() => {
												let retailer =
													localStorage.getItem(
														"retailer",
													);
												console.log(retailer);
												window.open(d.url, "_blank");
											}}>
											<View
												style={{
													padding: 17,
													borderWidth: 1,
													justifyContent: "center",
													alignItems: "center",
												}}>
												<Text
													style={{
														fontFamily: "Gotham",
														fontSize: 17,
														fontWeight: "600",
													}}>
													{/* {d.title} */}
													Buy Now
												</Text>
											</View>
										</TouchableOpacity>
									))}
							</View>
						</View>
					</View>
				</View>

				<Text style={styles.title}>
					Follow, Like, share and comment
				</Text>

				<View
					style={{
						justifyContent: "center",
						alignItems: "center",
					}}>
					<TouchableOpacity
						// style={styles.input}
						onPress={() => handleInstagramClick()}>
						<Image
							source={require("../assets/instagram.png")}
							style={{
								width: window.innerWidth * 0.8,
								height: 50,
								resizeMode: "contain",
								marginVertical: 15,
							}}
						/>
					</TouchableOpacity>

					<TouchableOpacity
						// style={styles.input}
						onPress={() => handleFacebookClick()}>
						<Image
							source={require("../assets/facebook.png")}
							style={{
								width: window.innerWidth * 0.8,
								height: 50,
								resizeMode: "contain",
								marginVertical: 15,
							}}
						/>
					</TouchableOpacity>
					{/* <Text
				style={{
					opacity: 0.7,
				}}>
				or
			</Text>
			<Text
				style={{
					opacity: 0.7,
					marginTop: 15,
				}}>
				Sign up to Join our community
			</Text>
			<View
				style={{
					height: 10,
				}}
			/>

			<TouchableOpacity
				style={{
					width: "100%",
					height: 40,
					borderRadius: 12,
					backgroundColor: "#E3A081",
					alignItems: "center",
					justifyContent: "center",
					marginTop: 16,
					marginBottom: 80,
				}}
				onPress={() => (window.location.href = "/signupform")}>
				<Text
					style={{
						color: "#fff",
						fontSize: 16,
					}}>
					Signup
				</Text>
			</TouchableOpacity> */}
				</View>
			</View>
		);
	}
};

const styles = StyleSheet.create({
	container: {},
	title: {
		fontSize: 20,
		fontWeight: "300",

		// marginBottom: 16,
		// marginTop: 25,
		color: "#6d6e70",
		// fontFamily: "Bodoni Moda",
		// fontFamily: "Lora",
	},
	input: {
		width: "100%",
		height: "auto",
		borderRadius: 12,
		borderWidth: 1,
		borderColor: "#ccc",
		paddingLeft: 16,
		marginBottom: 16,
		fontSize: 17,
	},
	row: {
		width: "100%",
		// flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",

		marginBottom: 20,
	},

	checkboxContainer: {
		flexDirection: "row",
		alignItems: "center",
		marginBottom: 16,
	},
	label: {
		marginLeft: 8,
		fontSize: 16,
	},
	button: {},
	buttonText: {
		color: "#fff",
		fontSize: 16,
	},
	signInContainer: {
		flexDirection: "row",
		marginTop: 16,
	},
	signInText: {
		fontSize: 16,
		marginRight: 8,
	},
	signInLink: {
		fontSize: 16,
		color: "#E3A081",
	},

	label: {
		fontSize: 16,
	},
	buttonContainer: {
		width: "100%",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		marginTop: 8,
	},
	button: {
		// width: "48%",
		flex: 1,
		height: 48,
		borderRadius: 12,
		backgroundColor: "#ccc",
		alignItems: "center",
		justifyContent: "center",
	},
	activeButton: {
		backgroundColor: "#E3A081",
	},
	buttonText: {
		color: "#fff",
		fontSize: 16,
	},
});

export default ProductPage;
